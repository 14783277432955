import px2rem from '@/utils/px2rem';
import { Popover } from 'react-bootstrap';
import styled from 'styled-components';

export const WalletPopover = styled(Popover)`
  background-color: #17171a;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  width: ${px2rem(200)};
  color: ${({ theme }) => theme.white};
  padding: ${px2rem(12)} ${px2rem(20)};

  .icCopy {
    cursor: pointer;
  }

  .wallet-tc,
  .wallet-btc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${px2rem(16)};
  }

  .wallet-item {
    display: flex;
    align-items: center;
    gap: ${px2rem(8)};
  }

  .wallet-link,
  .wallet-disconnect {
    display: flex;
    align-items: center;
    gap: ${px2rem(12)};
    cursor: pointer;
    :hover {
      opacity: 0.6;
    }
  }

  .wallet-link {
    margin-top: ${px2rem(20)};
    margin-bottom: ${px2rem(20)};
  }

  .divider {
    margin-bottom: ${px2rem(16)};
  }

  &.popover {
    /* display: none; */

    .popover-arrow {
      width: 100%;
      transform: translate(0px, 0px) !important;
    }

    .popover-arrow::after {
      width: 100%;
      border-bottom-color: transparent !important;
    }
  }
`;

export const WrapperWalletNotConnect = styled.button`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 8px;

  width: 187px;
  height: 44px;

  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;

  :hover {
    opacity: 0.8;
  }
`;

export const ConnectWallet = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
  /* identical to box height, or 243% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Color-newsystem/White/100 */

  color: #ffffff;
`;
