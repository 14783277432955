import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <rect width={48} height={48} fill="#18CDCA" rx={12} />
    <path
      fill="#fff"
      d="m25.428 14.795 1.08-1.064c2.121-2.089 5.574-2.14 7.707-.063a5.337 5.337 0 0 1 1.536 2.911 5.29 5.29 0 0 1-.474 3.248 2.536 2.536 0 0 0 .498 2.948 7.496 7.496 0 0 0 1.649-2.437 7.404 7.404 0 0 0-.008-5.745 7.497 7.497 0 0 0-1.656-2.432c-2.983-2.921-7.824-2.863-10.799.066L14.667 22.366l-.846-.834c-2.157-2.124-2.17-5.596.024-7.683a5.478 5.478 0 0 1 2.962-1.438 5.52 5.52 0 0 1 3.262.512 2.483 2.483 0 0 0 2.842-.434l.026-.025a.085.085 0 0 0 0-.122 7.68 7.68 0 0 0-5.294-2.13 7.68 7.68 0 0 0-5.308 2.097c-3.07 2.92-3.06 7.775-.041 10.747l10.29 10.136-1.092 1.077c-2.121 2.088-5.574 2.14-7.707.063a5.336 5.336 0 0 1-1.536-2.911 5.29 5.29 0 0 1 .474-3.248 2.538 2.538 0 0 0-.498-2.948 7.497 7.497 0 0 0-1.649 2.437 7.406 7.406 0 0 0 .008 5.745c.385.91.947 1.736 1.656 2.432 2.983 2.921 7.824 2.863 10.799-.066L33.346 25.62l.85.836c2.12 2.09 2.173 5.49.065 7.59a5.474 5.474 0 0 1-2.957 1.513 5.52 5.52 0 0 1-3.297-.466 2.646 2.646 0 0 0-2.994.49 7.628 7.628 0 0 0 2.474 1.624 7.72 7.72 0 0 0 5.833-.008 7.625 7.625 0 0 0 2.47-1.63c2.965-2.938 2.906-7.707-.068-10.636l-10.294-10.14Zm-1.306 16.883-7.918-7.798 7.687-7.57 7.918 7.797-7.687 7.57Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
